import {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Game as GameType } from '~types';
import Modal from './Modal';
import { useTranslation } from 'react-i18next';
import { useQuery, gql } from '@apollo/client';
import { useYaMetrica } from '~frontend/provider/yametrica';
import { useModal } from './hooks';
import { GetName, SteamItemImage } from '../item';
import Currency from '../Currency';
import { Loader } from '../Loader';
import { Button } from '~ui/button/button';
import { Price } from '~ui/index';
import GameChange from '../game/GameChange';
import { useProfile, useConfig } from '../hooks';
import './OutItemModalStyles.scss';
import Close from './closeIcon';

interface OutItemModalData {
  getGame: GameType;
}

interface OutItemModalVars {
  id: number;
}

interface OutItemModalProps {
  id: number;
  onClose: any;
}

const OutItemModal: FunctionComponent<OutItemModalProps> = memo(
  ({ id = 0, onClose }: OutItemModalProps) => {
    const getProfile = useProfile();
    const getConfig = useConfig();
    const { reachGoal } = useYaMetrica();
    const { t } = useTranslation();

    useEffect(() => {
      reachGoal('open_out_item_modal');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const { data, loading, error } = useQuery<
      OutItemModalData,
      OutItemModalVars
    >(
      gql`
        query OutItemModal($id: Int!) {
          getGame(id: $id) {
            id
            price
            priceSell
            status
            getItem {
              id
              color
              getImage
              getName
            }
          }
        }
      `,
      { variables: { id } },
    );

    const getGame = data?.getGame;

    // Объект с сопоставлением цветов и названий классов
    const rarityColors = {
      b0c3d9: 'item_consumer',
      '4b69ff': 'item_military',
      '5e98d9': 'item_industrial',
      '8847ff': 'item_restricted',
      d32ce6: 'item_classified',
      eb4b4b: 'item_covert',
      ffc600: 'item_legendary',
    };

    // Функция для получения названия класса по цвету
    const getRarityClassName = useCallback((color) => {
      console.log('color', color);
      return rarityColors[color] || '';
    }, []);

    const rarityClassName = useMemo(
      () => getRarityClassName(getGame?.getItem.color),
      [getGame?.getItem.color, getRarityClassName],
    );

    if (loading) {
      return <Loader />;
    } else if (error) {
      return <>OutItemModal | Error! {error.message}</>;
    }

    return (
      <>
        <div className="demo_modal" onClick={onClose}>
          <div
            className={`demo_modal_body`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="demo_modal_header">
              <h2>Выберите способ вывода</h2>
              <div className="demo_modal_close" onClick={onClose}>
                <Close />
              </div>
            </div>
            <div className="demo_modal_content">
              <div
                className={`demo_modal_prize demo_modal_prize_${rarityClassName}`}
              >
                <div className="demo_modal_prize_header">
                  <div className="demo_modal_prize_price">
                    {getConfig.isEU ? (
                      <>
                        <Currency />
                        <Price sum={getGame?.price} />
                      </>
                    ) : (
                      <>
                        <Price sum={getGame?.price} />
                        <Currency />
                      </>
                    )}
                  </div>
                  <div className="demo_modal_prize_percent">
                    {getGame?.chance}
                  </div>
                </div>
                <SteamItemImage
                  url={getGame?.getItem?.getImage}
                  size="218x164"
                  className="demo_modal_prize_image"
                />
                <div className="demo_modal_prize_info">
                  <div className="demo_modal_prize_tag">
                    {getGame?.getItem.getName.split('|')[0].trim()}
                  </div>
                  <div className="demo_modal_prize_name">
                    <span>{getGame?.getItem.quality}</span> |{' '}
                    {getGame?.getItem.getName.split('|')[1].trim()}
                  </div>
                </div>
              </div>
              {/* <Button
                className={`btn1_green demo_modal_button`}
                text={`Продать за ${getGame?.priceSell / 100}`}
                currencyComponent={<Currency />}
                disabled={true}
              /> */}
              <GameChange
                className="btn1 btn1_green demo_modal_button"
                getGame={getGame}
                type={0}
                onDone={onClose}
              >
                {/* <div className="btn-sale-cost-wrap">
                  <span className="btn-sale-cost">
                    {getConfig.isEU ? (
                      <>
                        <Currency />
                        <Price sum={getGame?.price} />
                      </>
                    ) : (
                      <>
                        <Price sum={getGame?.price} />
                        <Currency />
                      </>
                    )}
                  </span>
                </div> */}
                <span>{t('win-demo-modal btn-buy-text')}</span>
                <span>
                  {getConfig.isEU ? (
                    <>
                      <Currency />
                      <Price sum={getGame?.priceSell} />
                    </>
                  ) : (
                    <>
                      <Price sum={getGame?.priceSell} />
                      <Currency />
                    </>
                  )}
                </span>
              </GameChange>
              <div className="demo_modal_subtitle">
                Средства мгновенно поступят на Ваш баланс CS-Case
              </div>
              <div className="demo_modal_devider"></div>
              {/* <Button
                className={`btn1_dark_bordered demo_modal_button`}
                text="Получить трейд"
              /> */}
              <GameChange
                className="btn1_dark_bordered demo_modal_button"
                getGame={getGame}
                type={1}
                onDone={onClose}
              >
                <span>{t('Request trade')}</span>
              </GameChange>
              <div className="demo_modal_text">
                <p>
                  Уже через пару минут Вы получите трейд с предметом на свой
                  Steam аккаунт
                </p>
                <p className="demo_modal_text_red">
                  Принять его нужно в течение 5 минут
                </p>
              </div>
              <div className="demo_modal_devider"></div>
              <div className="demo_modal_text">
                <p>
                  Нажимая на кнопку "Получить трейд", вы соглашаетесь с тем, что
                  ваша{' '}
                  <a href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url">
                    трейд-ссылка
                  </a>{' '}
                  введена верно.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="box-modal_close arcticmodal-close" onClick={onClose}>
          <div className="icon material-icons">close</div>
        </div>
        <div className="modal-blue-title">{t('Choose withdraw method')}</div>
        <div className="demo-prize">
          <div
            className={`in-case-item active status-${getGame?.status} ${
              getGame?.status === 1 && 'wait'
            }`}
            data-rar={getGame?.getItem?.color}
            data-audio="5.wav"
            data-volume="0.4"
          >
            <div className="in-case-cost">
              {getConfig.isEU ? (
                <>
                  <Currency />
                  <Price sum={getGame?.price} />
                </>
              ) : (
                <>
                  <Price sum={getGame?.price} />
                  <Currency />
                </>
              )}
            </div>
            <div className="in-case-item-wrap">
              <div className="img">
                <div className="in-case-item-bg" />
                <SteamItemImage
                  url={getGame?.getItem?.getImage}
                  size="350x350"
                />
              </div>
              <div className="in-case-info">
                <div className="in-case-item-name">
                  <GetName getItem={getGame?.getItem} type={0} />
                </div>
                <div className="in-case-item-desc">
                  <GetName getItem={getGame?.getItem} type={1} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <GameChange
          className="btn-buy btn-icon"
          getGame={getGame}
          type={0}
          onDone={onClose}
        >
          <div className="btn-sale-cost-wrap">
            <span className="btn-sale-cost">
              {getConfig.isEU ? (
                <>
                  <Currency />
                  <Price sum={getGame?.price} />
                </>
              ) : (
                <>
                  <Price sum={getGame?.price} />
                  <Currency />
                </>
              )}
            </span>
          </div>
          <span className="icon material-icons">call_made</span>
          <span className="btn-buy-text">
            {t('win-demo-modal btn-buy-text')}
          </span>
          <span className="btn-buy-cost">
            {getConfig.isEU ? (
              <>
                <Currency />
                <Price sum={getGame?.priceSell} />
              </>
            ) : (
              <>
                <Price sum={getGame?.priceSell} />
                <Currency />
              </>
            )}
          </span>
        </GameChange>
        <div className="more-text">
          {t('You will instantly receive balance to your CS-Case account')}
        </div>
        <hr />
        <GameChange
          className="btn-buy btn-blue btn-icon"
          getGame={getGame}
          type={1}
          onDone={onClose}
        >
          <span
            className="icon material-icons"
            data-icon="call_received"
          ></span>
          <span className="btn-buy-text">{t('Request trade')}</span>
        </GameChange>
        <div className="more-text">
          {t(
            'In a couple of minutes you will receive a trade with the item on your Steam account',
          )}
          <br />
          <br />
          <span>{t('You need to take it within 5 minutes')}</span>
        </div>
        <hr />
        <div
          className="more-text"
          dangerouslySetInnerHTML={{
            __html: t('pressing_the_button_trade', {
              url: getProfile.tradeUrl,
            }),
          }}
        ></div> */}
      </>
    );
  },
);

const OutItemModalWrapper: FunctionComponent = () => {
  const modalName = 'OutItemModal';

  const {
    onClose,
    isOpen,
    props: { id },
  } = useModal<{ id: number }>(modalName);

  return (
    <Modal show={isOpen} onClose={onClose} width={406} modalName={modalName}>
      <div className="">
        <OutItemModal id={id} onClose={onClose} />
      </div>
    </Modal>
  );
};

export default OutItemModalWrapper;
